const env = process.env.NEXT_PUBLIC_APP_ENV;

export const initMetrics = (cb: () => void, enabled) => {
    if (enabled) {
        if (typeof window !== 'undefined') {
            if (window?.requestIdleCallback) {
                window.requestIdleCallback(
                    () => {
                        cb();
                    },
                    { timeout: 1000 }
                );
            } else {
                window.setTimeout(() => {
                    cb();
                }, 800);
            }
        }
    } else {
        console.log('dev: init metrics');
    }
};

export interface EventProperties {
    artist?: string;
    artistId?: string | number;
    artistName?: string;
    authenticated?: boolean;
    aside?: boolean;
    contest?: string;
    contestId?: number | string;
    contestSlug?: string;
    festival?: string;
    festivalId?: string | number;
    festivalName?: string;
    filter?: string;
    header?: boolean;
    footer?: boolean;
    label?: string;
    page?: string;
    path?: string;
    musicService?: string;
    type?: string;
    uuid?: string | number;
    url?: string;
    utm_campaign?: string;
    utm_content?: string;
    utm_medium?: string;
    utm_source?: string;
    utm_term?: string;
}

// Naming Convention - FeatureGroupThing: feature/group/thingOrAction || feature/thingOrAction
export const Events = {
    ClickedAddToAppleCalendar: 'clicked/addToCalendar/apple',
    ClickedAddToGoogleCalendar: 'clicked/addToCalendar/google',
    ClickedAddToOffice365Calendar: 'clicked/addToCalendar/office365',
    ClickedAddToOutlookCalendar: 'clicked/addToCalendar/outlook',
    ClickedAddToYahooCalendar: 'clicked/addToCalendar/yahoo',
    ClickedCompare: 'clicked/compare',
    ClickedDownloadIcsCalendar: 'clicked/addToCalendar/ics',
    ClickedDownloadAndroid: 'clicked/download/android',
    ClickedDownloadIos: 'clicked/download/ios',
    ClickedDownloadFeatures: 'clicked/download/features',
    ClickedDownloadReviews: 'clicked/download/reviews',
    ClickedEnabledLocation: 'clicked/location/enable',
    ClickedExport: 'clicked/export',
    ClickedFollow: 'clicked/follow',
    ClickedGetTheApp: 'clicked/getTheApp',
    ClickedHome: 'clicked/home',
    ClickedMusicService: 'clicked/musicService',
    ClickedSearch: 'clicked/search',
    ClickedShareDownload: 'clicked/share/download',
    ClickedShareFacebook: 'clicked/share/facebook',
    ClickedShareLinkedIn: 'clicked/share/linkedIn',
    ClickedShareTwitter: 'clicked/share/twitter',
    ClickedShareWhatsApp: 'clicked/share/whatsApp',
    ClickedShareCopyLink: 'clicked/share/copyLink',
    ClickedSignUp: 'clicked/signUp',
    ClickedSocialCta: 'clicked/social/cta',
    ClickedTickets: 'clicked/tickets',
    DeletedAccount: 'clicked/account/delete',
    SignInWithApple: 'signIn/with/apple',
    SignInWithAppleError: 'error/signIn/with/apple',
    SignInWithEmail: 'signIn/with/email',
    SignInWithEmailError: 'error/signIn/with/email',
    SignInWithFacebook: 'signIn/with/facebook',
    SignInWithFacebookError: 'error/signIn/with/facebook',
    SignInWithSpotify: 'signIn/with/spotify',
    SignInWithSpotifyError: 'error/signIn/with/spotify',
    SignUpWithApple: 'signUp/with/apple',
    SignUpWithAppleError: 'error/signUp/with/apple',
    SignUpWithFacebook: 'signUp/with/facebook',
    SignUpWithFacebookError: 'error/signUp/with/facebook',
    SignUpWithSpotify: 'signUp/with/spotify',
    SignUpWithSpotifyError: 'error/signUp/with/spotify',
    SignUpWithEmail: 'signUp/with/email',
    SignUpWithEmailError: 'error/signUp/with/email',
    SignUpSuccess: 'signUp/success',
    ViewedPage: 'viewed',
};

export const FacebookEvents = {
    ClickedSocialCta: 'ClickedSocialCta',
    Search: 'Search',
};

export const trackEvent = async (
    eventName: string,
    eventProperties?: EventProperties
) => {
    if (env === 'staging' || env === 'production') {
        const amplitudeApiKey = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;

        if (amplitudeApiKey) {
            const amplitude = await import('@amplitude/analytics-browser');

            if (amplitude)
                amplitude.track(`/web/${eventName}`, eventProperties);
        }

        if (window?.twq) {
            if (eventName === Events.ClickedDownloadAndroid) {
                window.twq('event', 'tw-okbo6-okd22', {});
            }

            if (eventName === Events.ClickedDownloadIos) {
                window.twq('event', 'tw-okbo6-okd2d', {});
            }

            if (eventName === Events.SignUpSuccess) {
                window.twq('event', 'tw-okbo6-okd2i', {
                    conversion_id: eventProperties?.uuid,
                });
            }
        }

        trackFBEvent(eventName, eventProperties);
    } else {
        console.log(eventName, eventProperties);
    }
};

export const trackFBEvent = (
    eventName: string,
    eventProperties?: EventProperties
) => {
    const fbEventName = `web_${eventName.replace(new RegExp(/\//g), '_')}`;

    if (env === 'staging' || env === 'production') {
        if (window?.fbq) {
            window.fbq('trackCustom', fbEventName, eventProperties);
        }
    } else {
        console.log(fbEventName, eventProperties);
    }
};
