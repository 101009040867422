import React from 'react';

import classNames from 'classnames/bind';

import Masonry from './Masonry';
import Row from './Row';
import useHTTP from '@/hooks/useHTTP';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    limit?: number;
    layout?: 'masonry' | 'row';
    hideMeta?: boolean;
    interactive?: boolean;
}

const FestivalTiles = ({
    limit = 25,
    layout = 'row',
    hideMeta = false,
    interactive = true,
}: Props) => {
    const { data, loading } = useHTTP({
        request: {
            endpoint: '/festivals',
            params: {
                _limit: limit,
                _filter: { upcoming: 'ASC' },
                featured_eq: false,
            },
            requestKey: 'home-featured-festivals-tiles',
            cache: true,
        },
        method: 'get',
    });

    return (
        <div
            className={cx(
                'festival_tiles',
                {
                    '--masonry': layout === 'masonry',
                },
                {
                    '--row': layout === 'row',
                }
            )}>
            {layout === 'row' ? (
                <Row
                    festivals={data?.data}
                    hideMeta={hideMeta}
                    interactive={interactive}
                />
            ) : null}
            {layout === 'masonry' ? (
                <Masonry
                    festivals={data?.data}
                    hideMeta={hideMeta}
                    interactive={interactive}
                    limit={limit}
                />
            ) : null}
        </div>
    );
};

export default React.memo(FestivalTiles);
