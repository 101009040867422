export const MAX_FESTIVALS_TO_COMPARE = 5;
export const MIN_FESTIVALS_TO_COMPARE = 2;
export const MIN_CHARS_FOR_SEARCH = 3;
export const DESKTOP_MAX_PER_PAGE = 20;
export const MOBILE_MAX_PER_PAGE = 10;
export const ARTISTS_DESKTOP_MAX_PER_PAGE = 20;
export const ARTISTS_MOBILE_MAX_PER_PAGE = 6;
export const GROUPED_MAX_PER_ROW = 10;
export const LOCATIONS_DESKTOP_MAX_PER_PAGE = 8;
export const LOCATIONS_MOBILE_MAX_PER_PAGE = 4;
export const MAX_TAGS_TO_SHOW_IN_RESULTS = 8;

export enum AuthAction {
    Register = 'register',
    Login = 'login',
}

export const defaultUtmParams = {
    utm_campaign: 'app download',
    utm_source: 'website',
    utm_medium: 'festgps',
    utm_content: '',
    utm_term: '',
};

export enum Pages {
    Download = 'Download',
    Footer = 'Footer',
    Header = 'Header',
    Home = 'Home',
    SignUp = 'SignUp',
}

export enum SearchSources {
    Festivals = 'festivals',
    Artists = 'artists',
    Locations = 'locations',
}

export const enum SearchPlaceholders {
    Festivals = 'Find your favorite festival ...',
    Artists = 'Find your favorite artist ...',
    Locations = 'Find festivals by location ...',
}

export enum InputType {
    Email = 'email',
    Password = 'password',
    Text = 'text',
    Hidden = 'hidden',
}

export enum Hints {
    Password = 'The password should be at least 8 characters or more. To make it stronger use upper and lower case letters, numbers and special characters.',
}

export enum NotificationsType {
    EmailUpdates = 'optinEmailUpdates',
    EmailFestivalRecommendations = 'optinEmailFestivalRecommendations',
    EmailOffers = 'optinEmailOffers',
}

export enum ErrorMessage {
    GenericTitle = 'An error occurred',
    GenericMessage = 'An unexpected error has occurred, please try again later.',
    ServiceUnavailableMessage = 'Service is unavailable, please try again later.',
    IncorrectEmailPassword = 'Incorrect email address/password.',
    CaptchaErrorTitle = "Please verify you're are a human :)",
    CaptchaErrorMessage = 'Before you can proceed with this request.',
    FormFieldsTitle = 'All fields are required',
    FormFieldsMessage = 'Please verify that all required fields have been filled out.',
}

export enum Greetings {
    GoodMorning = 'Good Morning',
    GoodDay = 'Hi!',
    GoodAfternoon = 'Good Afternoon',
    GoodEvening = 'Good Evening',
    GoodNight = 'Good Night',
}

export enum CompareMessages {
    NoFestivals = 'Add festivals to compare and see how many of your favorites are playing in 1, 2, 3 or more of the same festivals',
    NoArtistsInCommon = 'Add more festivals with your favorites to compare, to improve results',
    MinFestivalsRequired = 'Select at least two festivals to start comparing lineups, to see which festivals have your most loved artists.',
    MinThresholdNotMet = 'At least 2 festivals or more are needed to start comparing lineups',
}
